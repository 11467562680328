<template>
  <div>
    <el-form
      :model="params"
      :show-overflow-tooltip="true"
      ref="params"
      :rules="rules"
      label-width="130px"
      class="addDailog"
    >
      <el-form-item label="节点名称" prop="name">
        <el-input
          type="text"
          v-model="params.name"
          maxlength="32"
          show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item label="相关角色" prop="roleCodeList">
        <el-select
          v-model="params.roleCodeList"
          multiple
          placeholder="请选择"
          @visible-change="departmentList($event)"
          style="width: 100%"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in options"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="相关文件" prop="workflowFileIdList">
        <el-select
          v-model="params.workflowFileIdList"
          filterable
          :reserve-keyword="false"
          placeholder="请输入关键词从节点中搜索"
          @visible-change="workflowFileSelectList($event)"
          clearable
          multiple
          style="width: 100%"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in workflowListOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-col style="float: left">
          <el-link type="danger" :underline="false" @click="openAddProcessDocument"
            >无合适文件？去新建</el-link
          >
        </el-col>
      </el-form-item>
      <el-form-item label="节点描述" prop="description">
        <el-input type="text" v-model="params.description"></el-input>
      </el-form-item>
      <el-form-item label="是否评估" prop="evaluate">
        <el-radio v-model="params.evaluate" label="1">是</el-radio>
        <el-radio v-model="params.evaluate" label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否记录会议纪要" prop="meeting">
        <el-radio v-model="params.meeting" label="1">是</el-radio>
        <el-radio v-model="params.meeting" label="0">否</el-radio>
      </el-form-item>
    </el-form>
    <!-- 编辑 -->
    <addProcessDocument
      v-if="addProcessDocumentDialog"
      :editDialog="addProcessDocumentDialog"
      type="add"
      v-on:listenEditClose="listenEditClose"
      
    ></addProcessDocument>
  </div>
</template>

<script>
import { findRoleList } from "../../api/processNode.js";
import { selectListWorkflowFile } from "@/api/processDocument";
import addProcessDocument from "@/components/processDocument/edit";

export default {
  name: "edit",
  props: ["msPd"],
  data() {
    return {
      options: [],
      params: {
        name: null,
        roleCodeList: [],
        relevantFile: null,
        description: null,
        evaluate: "1",
        meeting: "1",
        workflowFileIdList: [],
      },
      rules: {},
      workflowListOptions: [],
      mapTrue: true,
      addProcessDocumentDialog:false
    };
  },
  computed: {},
  watch: {
    msPd(n, o) {
      //监听新增|编辑传过来的对象
      //箭头函数  不然会发生this改变
      this.objProject(n);
    },
    deep: true,
  },
  methods: {
    openAddProcessDocument(){
      this.addProcessDocumentDialog = true;
    },
     // 
    listenEditClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.addProcessDocumentDialog = !isClose;
      if (type == "sure") { 
      }
    },
    workflowFileSelectList(cb) {
      var that = this;
      if (cb) {
        selectListWorkflowFile({}).then((response) => {
          if (response.success) {
            that.workflowListOptions = response.data || [];
            if (that.msPd && that.mapTrue) {
              that.msPd.workflowFileList.map(function (item, index) {
                that.params.workflowFileIdList.push(item.id);
              });
              that.mapTrue = false;
            }
          }
        });
      }
    },
    objProject(val) {
      //判断是否回显
      if (val) {
        this.departmentList(true);
        this.workflowFileSelectList(true);
        this.params = val;
        this.params.workflowFileIdList = [];
        this.params.evaluate = String(this.params.evaluate);
        this.params.meeting = String(this.params.meeting);
      } else {
        this.params.name = null;
        this.params.roleCodeList = [];
        this.params.relevantFile = null;
        this.params.description = null;
      }
    },
    departmentList(cb) {
      if (cb) {
        findRoleList({}).then((res) => {
          if (res.success) {
            this.options = res.data;
          }
        });
      }
    },
  },
  created() {
    this.objProject();
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  components: {
    addProcessDocument,
  },
};
</script>

<style lang="scss" scoped>
.addDialog {
  text-align: left;
  width: 100%;

  .el-form-item {
    width: 100% !important;
    display: flex;
    justify-content: flex-start;
    .flexJfAc {
    }
  }
}
.addDialog .el-form-item >>> .el-input {
  width: 100% !important;
}
.addDialog .el-form-item >>> .el-select {
  width: 100% !important;
}
</style>