<template>
  <div>
    <el-form
      class="projectFrom"
      :model="queryParams"
      ref="queryParams"
      label-width="120px"
    >
      <el-form-item label="节点名称" prop="name">
        <el-input
          type="text"
          v-model="queryParams.name"
          placeholder="请输入节点名称"
          width="200"
          clearable
          @change="nameChange()"
        ></el-input>
      </el-form-item>
      <el-form-item label="创建人" prop="createUser">
        <el-select
          v-model="queryParams.createUser"
          filterable
          placeholder="请搜索或选择创建人"
          @visible-change="projectTeam($event)"
          @change="createUserChange($event)"
          clearable
          class="childWidth"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in internalUseroptionsList"
            :key="item.uid"
            :label="item.realName"
            :value="item.uid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="任务设置状态" prop="isSetTaskTemplate">
        <el-select
          v-model="queryParams.isSetTaskTemplate"
          placeholder="请选择任务设置状态"
          clearable
          @change="isSetTaskTemplateChange()"
        >
          <el-option
            v-for="item in setTaskStatus"
            :label="item.name"
            :value="item.code"
            :key="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <div style="margin-left: 80px; float: right">
        <el-button @click="resetQueryParams">重置</el-button>
        <el-button
          type="primary"
          @click="queryProjectList(1, pageInfo.pageSize)"
          >查询</el-button
        >
        <el-button
          style="marginright: 20px"
          type="primary"
          @click="openDialog('')"
          v-permission="['admin:workflow:node:add']"
          >新增</el-button
        >
      </div>
    </el-form>
    <!-- 表格 -->
    <el-table :data="tableData" border style="width: 100%; margintop: 20px">
      <el-table-column
        prop="id"
        align="center"
        label="流程节点ID"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="name"
        align="center"
        label="流程节点名称"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="roleNames"
        align="center"
        label="角色"
      ></el-table-column>

      <el-table-column label="相关文件" prop="workflowFileList">
        <template slot-scope="scope">
          <span
            class="span-tag-list"
            v-for="(item, index) in scope.row.workflowFileList"
            :key="item.id"
          >
            <span>{{ index + 1 }}.{{ item.name }} </span>
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="description"
        :show-overflow-tooltip="true"
        align="center"
        label="流程描述"
      ></el-table-column>
      <el-table-column
        prop="creatorName"
        align="center"
        label=" 创建人"
      ></el-table-column>
      <el-table-column prop="createDate" align="center" label="创建时间">
        <template slot-scope="scope">
          <span>{{ scope.row.createDate | formatDateFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作" width="270">
        <template slot-scope="scope">
          <el-button
            @click="openDialog(scope.row)"
            type="primary"
            size="small"
            v-permission="['admin:workflow:node:edit']"
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="delClick(scope.row.id)"
            v-permission="['admin:workflow:node:delete']"
            >删除</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="setTask(scope.row)"
            v-permission="['admin:workflow:node:set_tasks']"
            >设置任务</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="page-info-class">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="this.pageInfo.pageNum"
        :page-sizes="[10, 20]"
        :page-size="this.pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="this.pageInfo.total"
      ></el-pagination>
    </div>
    <!-- 编辑-->
    <el-dialog
      :title="this.dialogTitle"
      class="addDom"
      :visible.sync="dialogVisible"
      width="40%"
      v-if="dialogVisible"
      :close-on-click-modal="false"
    >
      <editProject ref="editProject" :msPd="msPd"></editProject>
      <span center slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addProject()">确 定</el-button>
        <el-button @click="resetForm()">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 设置任务 -->
    <setTask
      v-if="setTaskDialog"
      :setTaskDialog="setTaskDialog"
      v-on:listensetTaskClose="listensetTaskClose"
      :id="processNodeId"
    ></setTask>
  </div>
</template>

<script>
import { projectTeam } from "@/api/projectManage";
import { formatDate } from "@/common/date";
import editProject from "../components/processNode/edit";
import setTask from "../components/processNode/setTask";
//setTask
import {
  addProjectNode,
  editProjectNode,
  queryProjectNodeList,
  delProjectNode,
  findOneProjectNode,
} from "../api/processNode";
export default {
  name: "processNode",
  data() {
    return {
      dialogVisible: false,
      setTaskDialog: false,
      tableData: [],
      msPd: {},
      dialogTitle: "新增节点",
      relatedDocuments: "",
      processDescription: "",
      pageInfo: {
        total: 1,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      queryParams: {
        pageSize: 10,
        pageNum: 1,
      },
      processNodeId: null, //流程节点Id
      setTaskStatus: [
        { name: "全部", code: "" },
        { name: "未设置", code: 0 },
        { name: "已设置", code: 1 },
      ],
      internalUseroptionsList: [],
    };
  },
  mounted() {
    this.queryProjectList(1, this.pageInfo.pageSize);

    //
  },
  created() {
    if (this.$route.query.type == "fromProcessLibrary") {
      this.openDialog("");
    }
  },
  watch: {},
  components: {
    editProject,
    setTask,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  methods: {
    projectTeam(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },
    nameChange() {
      this.$forceUpdate();
    },
    createUserChange() {
      this.$forceUpdate();
    },
    isSetTaskTemplateChange() {
      this.$forceUpdate();
    },
    resetQueryParams() {
      this.$refs["queryParams"].resetFields(); //重置form
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.queryProjectList(1, this.pageInfo.pageSize);
    },
    //setTask 设置模版任务
    setTask(item) {
      this.processNodeId = item.id;
      this.setTaskDialog = true;
    },
    listensetTaskClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.setTaskDialog = !isClose;
      if (type == "sure") {
        this.queryProjectList(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
    openDialog(val) {
      this.dialogVisible = true;
      if (val === "") {
        this.dialogTitle = "新增节点";
        this.msPd = val;
      } else {
        this.dialogTitle = "编辑节点";
        findOneProjectNode({ id: val.id }).then((res) => {
          if (res.success) {
            this.msPd = res.data;
          }
        });
      }

      this.dialogStatus = true;
    },
    addProject() {
      //表单添加|编辑
      let form = this.$refs["editProject"].params;
      console.log(form);
      if (
        form.workflowFileIdList.length > 0 ||
        form.evaluate == 1 ||
        form.meeting == 1
      ) {
        console.log("可以");
      } else {
        this.$message({
          type: "warning",
          message: "相关文件、评估、记录会议纪要必须选择其中一个!",
        });
        return false;
      }
      form.operationCode = "OC001";
      if (form.id) {
        editProjectNode(form).then((res) => {
          //编辑
          console.log(res);
          if (res.success) {
            this.$message({ type: "success", message: "编辑成功!" });
            this.queryProjectList(1, this.pageInfo.pageSize);
          }
          this.pageInfo.pageNum = res.pageNum;
          this.pageInfo.pageSize = res.pageSize;
          this.pageInfo.total = res.total;
          this.pageInfo.pageCount = res.pageCount;
        });
      } else {
        console.log(form);
        addProjectNode(form).then((res) => {
          //添加
          if (res.success) {
            this.$message({ type: "success", message: "添加成功!" });
            this.$notify({
              title: "警告",
              message: "请去设置任务",
              type: "warning",
            });
            this.queryProjectList(1, this.pageInfo.pageSize);
          }
        });
      }
      this.dialogVisible = false;
    },
    resetForm() {
      // this.$refs["params"].resetFields();
      this.dialogVisible = false;
    },
    // 删除
    delClick(id) {
      this.$confirm("确定要删除此条节点, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          delProjectNode(id).then((res) => {
            if (res.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.queryProjectList(
                this.pageInfo.pageNum,
                this.pageInfo.pageSize
              );
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    queryProjectList(num, pageSize) {
      //进入页面查询数据列表
      this.queryParams.pageNum = num;
      this.queryParams.pageSize = pageSize;
      queryProjectNodeList(this.queryParams).then((res) => {
        this.tableData = res.data;
        if (res.total != null) {
          this.pageInfo.pageNum = res.pageNum;
          this.pageInfo.pageSize = res.pageSize;
          this.pageInfo.total = res.total;
          this.pageInfo.pageCount = res.pageCount;
        } else {
          this.pageInfo.total = 0;
        }
      });
    },
    handleSizeChange(val) {
      this.queryProjectList(1, val);
    },
    handleCurrentChange(val) {
      if (val > this.pageInfo.pageCount) {
        val = this.pageInfo.pageCount;
      }
      this.queryProjectList(val, this.pageInfo.pageSize);
    },
  },
};
</script>
<style scoped>
.addDom >>> .el-dialog__footer {
  text-align: center;
}
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.projectFrom .el-form-item {
  width: 20rem;
  flex-shrink: 0;
}
.editDialogs >>> .el-dialog__body {
  text-align: left;
}
.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}
</style>