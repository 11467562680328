<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="setTaskDialog"
    center
    @close="dialogClose"
    width="1200px"
    :close-on-click-modal="false"
  >
    <el-form>
      <el-row type="flex" justify="end">
        <el-form-item>
          <el-button
            type="primary"
            @click="openDialog('add')"
            v-permission="['admin:task:template:add']"
            >新增</el-button
          >
        </el-form-item>
      </el-row>
    </el-form>
    <!-- 表格 -->

    <el-table
      v-loading="loadingTable"
      :data="tableData"
      border
      style="width: 100%; margintop: 20px"
    >
      <el-table-column
        prop="id"
        align="center"
        label="任务编号"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="name"
        align="center"
        label="任务"
      ></el-table-column>
      <el-table-column
        prop="operationName"
        align="center"
        label="关联产出"
        width="180"
      ></el-table-column>

      <el-table-column
        prop="createDate"
        align="center"
        width="180"
        label="创建时间"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.createDate | formatDateFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作" width="270">
        <template slot-scope="scope">
          <el-button
            @click="openDialog('edit', scope.row.id)"
            type="primary"
            size="small"
            v-permission="['admin:task:template:edit']"
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="delClick(scope.row.id)"
            v-permission="['admin:task:template:delete']"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <!-- <div class="page-info-class">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="this.pageNum"
        :page-sizes="[10, 20]"
        :page-size="this.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="this.total"
      ></el-pagination>
    </div> -->

    <!-- 编辑 -->
    <setTaskEdit
      v-if="setTaskEditDialog"
      :setTaskEditDialog="setTaskEditDialog"
      v-on:listenSetTaskEditClose="listenSetTaskEditClose"
      :id="taskTemplateId"
      :nodeId="id"
      :type="setTaskTypeDialog"
    ></setTaskEdit>
  </el-dialog>
</template>

<script>
// import Vue from "vue";
import { taskTemplateList, deleteTaskTemplate } from "@/api/processNode";

import { formatDate } from "@/common/date";
import setTaskEdit from "./setTaskEdit";

export default {
  name: "setTask",
  data() {
    return {
      title: "任务模版",
      token: null,
      pageNum: 1,
      pageSize: 10,
      total: null,
      pageCount: null,
      loadingTable: false,
      tableData: [],
      setTaskEditDialog: false,
      setTaskTypeDialog: null,
      taskTemplateId: null,
    };
  },
  props: {
    setTaskDialog: Boolean,
    id: String,
  },
  components: {
    setTaskEdit,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  created() {
    this.taskTemplateList(this.id, 1, this.pageSize);
  },
  methods: {
    // 删除
    delClick(id) {
      this.$confirm("确定要删除此条模版任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          deleteTaskTemplate(id).then((res) => {
            if (res.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.taskTemplateList(this.id, this.pageNum, this.pageSize);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //
    listenSetTaskEditClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.setTaskEditDialog = !isClose;
      if (type == "sure") {
        this.taskTemplateList(this.id, this.pageNum, this.pageSize);
      }
    },
    //新增编辑弹窗显示
    openDialog(type, id, name) {
      console.log(id);
      if (type == "edit") {
        this.setTaskTypeDialog = "edit";
        this.taskTemplateId = id;
      } else if (type == "add") {
        this.setTaskTypeDialog = "add";
      }
      this.setTaskEditDialog = true;
    },
    //列表
    taskTemplateList(id, pageNum, pageSize) {
      this.loadingTable = true;
      let info = {};
      info.businessType = "1";
      info.businessId = id;
      taskTemplateList(info).then((res) => {
        if (res.success) {
          let info = res.data;
          this.tableData = info;
          //   this.pageNum = info.pageNum;
          //   this.pageSize = info.pageSize;
          //   this.total = info.total;
          //   this.pageCount = info.pageCount;
          this.loadingTable = false;
        }
      });
    },
    handleSizeChange(val) {
      this.taskTemplateList(this.id, 1, val);
    },
    handleCurrentChange(val) {
      if (val > this.pageCount) {
        val = this.pageCount;
      }
      this.taskTemplateList(this.id, val, this.pageSize);
    },

    //弹窗关闭
    dialogClose() {
      this.formData = {};
      this.$emit("listensetTaskClose", { type: "cancel", isClose: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}
.buttonGrop {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin-right: 100px;
  }
}
</style>