<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="setTaskEditDialog"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
    append-to-body
  >
    <div class="bar">
      <li class="width50">
        <span>任务名称：</span>
        <el-input v-model="formData.name" class="childWidth"></el-input>
      </li>
      <li class="width100">
        <span>任务描述：</span>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2 }"
          v-model="formData.description"
          class="childWidth"
        ></el-input>
      </li>
      <li class="width50">
        <span>操作：</span>
        <el-select
          v-model="formData.operationId"
          filterable
          :reserve-keyword="false"
          placeholder="请输入关键词从节点中搜索"
          @visible-change="workflowOperationSelectList($event)"
          clearable
          class="childWidth"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in workflowListOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </li>
      
    </div>

    <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
// import Vue from "vue";
import {
  addTaskTemplate,
  editTaskTemplate,
  detailTaskTemplate,
  workflowOperationSelectList,
} from "@/api/processNode";
 
import { formatDate } from "@/common/date";
export default {
  name: "setTaskEdit",
  data() {
    return {
      title: "新增",
      token: null,
      formData: { 
        businessType: null,
      },


      searchPorjectListLoading: false,
      searchWorkflowListLoading: false,
      porjectListOptions: null,
      workflowListOptions: null,
      investorwListOptions: null,
      internalUseroptionsList: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      pageNum: 1,
      pageSize: 10,
      total: null,
      pageCount: null,
      loadingTable: false,
      statusList: [
        { key: "未开始", value: 0 },
        { key: "进行中", value: 1 },
        { key: "已完成", value: 2 },
      ],
    };
  },
  props: {
    type: String,
    setTaskEditDialog: Boolean,
    id: String,
    nodeId:String,
    businessType: Number,
  },
  components: {},
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  created() {
    if (this.type == "edit") {
      this.title = "编辑";
      this.workflowOperationSelectList(true)
      this.detailTaskTemplate(this.id);
    } else if (this.type == "add") {
      this.title = "新增";
    }
    this.formData.businessType = "1";
      this.formData.businessId = this.nodeId;

  },
  methods: {
    workflowOperationSelectList(cb) {
      if (cb) {
        let param = {};
        param.name = null;
        param.relevantFile = null;
        param.nodeId = this.nodeId;
        workflowOperationSelectList(param).then((response) => {
          if (response.success) {
            this.workflowListOptions = response.data;
          }
        });
      }
    },

    detailTaskTemplate(id) {
      this.loadingTable = true;
      detailTaskTemplate(id).then((res) => {
        if (res.success) {
          let info = res.data;
          this.formData = info; 
          this.loadingTable = false; 
        }
      });
    },

    //弹窗关闭
    dialogClose() {
      this.formData = {};
      this.$emit("listenSetTaskEditClose", { type: "cancel", isClose: true });
    },

    add() {
      addTaskTemplate(this.formData).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "添加成功!" });
          this.formData = {};
          this.$emit("listenSetTaskEditClose", { type: "sure", isClose: true });
        }
      });
    },
    edit() {
      editTaskTemplate(this.formData).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "编辑成功!" });
          this.formData = {};
          this.$emit("listenSetTaskEditClose", { type: "sure", isClose: true });
        }
      });
    },
    //确定
    sure() {
      
      // return false
      let { 
        id, 
        businessId,
        name,
        operationCode,
        description
      } = this.formData;
      console.log(this.formData);

      if (this.type == "add") {
        this.add();
      } else if (this.type == "edit") {
        this.edit();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}
.buttonGrop {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin-right: 100px;
  }
}
</style>